/*
    Optics by Pixelarity
    pixelarity.com | hello@pixelarity.com
    License: pixelarity.com/license
*/
var $ = window.jQuery = window.$ = require("jquery");
require("jquery.scrollex");
require("scrolly");
require("./util");
import breakpoints from "./breakpoints.min";
import browser from "./browser.min";
import { isMobile, browser as br } from "./responsive";

if (isMobile) {
  console.log("Mobile/tablet device detected");
  $("#menu-listen").hide();
} else {
  console.log("Desktop device detected");
  $("#menu-mobile").hide();
}
if (br.os.name === "iOS") {
  console.log("iOS device detected");
}

var $window = $(window),
  $body = $('body'),
  $banner = $('#banner'),
  $header = $('#header');

// Breakpoints.
breakpoints({
  xlarge: ['1281px', '1680px'],
  large: ['981px', '1280px'],
  medium: ['737px', '980px'],
  small: ['481px', '736px'],
  xsmall: [null, '480px']
});

// Play initial animations on page load.
$window.on('load', function() {
  window.setTimeout(function() {
    $body.removeClass('is-preload');
  }, 100);
});

// Mobile?
if (browser.mobile)
  $body.addClass('is-mobile');
else {

  breakpoints.on('>medium', function() {
    $body.removeClass('is-mobile');
  });

  breakpoints.on('<=medium', function() {
    $body.addClass('is-mobile');
  });

}

// Scrolly.
// $('.scrolly')
//	.scrolly({
//		speed: 1500,
//		offset: $header.outerHeight()
//	});

// Nav.
$('#nav')
  .append('<a href="#nav" class="close"></a>')
  .appendTo($body)
  .panel({
    delay: 500,
    hideOnClick: true,
    hideOnSwipe: true,
    resetScroll: true,
    resetForms: true,
    side: 'right',
    target: $body,
    visibleClass: 'is-nav-visible'
  });

// Header.
if ($banner.length > 0
  && $header.hasClass('alt')) {

  $window.on('resize', function() { $window.trigger('scroll'); });

  $banner.scrollex({
    bottom: $header.outerHeight() + 5,
    terminate: function() { $header.removeClass('alt'); },
    enter: function() { $header.addClass('alt'); },
    leave: function() { $header.removeClass('alt'); }
  });

}

// Parallax.
if (browser.name == 'ie'
  || browser.name == 'edge'
  || browser.mobile) {

  $.fn._parallax = function() {
    return $(this);
  };

}
else {

  $.fn._parallax = function() {

    var $this = $(this);

    if (this.length == 0)
      return $this;

    if (this.length > 1) {

      for (var i = 0; i < this.length; i++)
        $(this[i])._parallax(userOptions);

      return $this;

    }

    $this.each(function() {

      var $t = $(this),
        on, off;

      on = function() {

        $t.css('background-position', 'center 100%, center 100%, center 0px');

        $window
          .on('scroll._parallax', function() {

            var pos = parseInt($window.scrollTop()) - parseInt($t.position().top);

            $t.css('background-position', 'center 100%, center 100%, center ' + (pos * -0.15) + 'px');

          });

      };

      off = function() {

        $t
          .css('background-position', '');

        $window
          .off('scroll._parallax');

      };

      breakpoints.on('<=medium', off);
      breakpoints.on('>medium', on);

    });

    return $(this);

  };

  $window
    .on('load resize', function() {
      $window.trigger('scroll');
    });

}

// Banner.
$banner._parallax();

// Main header.
$('#main > header')._parallax();
